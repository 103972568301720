<template>
  <div class="detail-wrapper">
    <div class="student-detail">
      <div class="item-detail mt32">
        <span class="item-type mr32">学生类型</span>
        <div class="item-mess">{{detail.studentType || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">在读高校</span>
        <div class="item-mess van-ellipsis">{{detail.college || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">现有学历</span>
        <div class="item-mess van-ellipsis">{{detail.degreeName || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">院系</span>
        <div class="item-mess van-ellipsis">{{detail.department || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">专业</span>
        <div class="item-mess van-ellipsis">{{detail.majorName || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">班级</span>
        <div class="item-mess van-ellipsis">{{detail.classNo || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">寝室号</span>
        <div class="item-mess van-ellipsis">{{detail.roomInfo || ''}}</div>
      </div>
    </div>
    <div class="teacher-detail">
      <div class="item-detail mt32">
        <span class="item-type mr32">ID</span>
        <div class="item-mess">{{detail.idNo || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">负责人</span>
        <div class="item-mess van-ellipsis">{{detail.ownerName || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">前负责人</span>
        <div class="item-mess van-ellipsis">{{detail.beforeOwner || '--'}}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">创建时间</span>
        <div
          class="item-mess van-ellipsis"
        >{{detail.createAt ? formatTimeStamp(new Date(detail.createAt)) : '--' }}</div>
      </div>
      <div class="item-detail mt32">
        <span class="item-type mr32">更新时间</span>
        <div
          class="item-mess van-ellipsis"
        >{{detail.updateAt ? formatTimeStamp(new Date(detail.updateAt)): '--' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCustomerDetails } from "@/api/index";
import { reactive, toRefs } from "vue";
import { formatTimeStamp } from "@/utils/commUtil";
export default {
  setup() {
    let personalDeatil = reactive({
      detail: {},
    });
    const getCustomerDetail = () => {
      getCustomerDetails({
        customerId: localStorage.getItem("customerId"),
      }).then((res) => {
        console.log(res);
        if (res && res.body) {
          personalDeatil.detail = res.body;
        }
      });
    };
    getCustomerDetail();
    return {
      personalDeatil,
      formatTimeStamp,
      ...toRefs(personalDeatil),
    };
  },
};
</script>
<style lang="less" scoped>
.detail-wrapper {
  padding: 0 32px;
  margin-bottom: 4vh;
  .student-detail {
    padding-bottom: 32px;
    border-bottom: 1px solid #eeeeee;
  }
  .item-detail {
    display: flex;
    .item-type {
      display: inline-block;
      width: 112px;
      font-size: 26px;
      color: #999999;
    }
    .item-mess {
      max-width: calc(100% - 112px);
      font-size: 26px;
      color: #333;
    }
  }
  .teacher-detail {
    padding-bottom: 40px;
  }
}
</style>