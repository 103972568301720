<template>
  <div v-if="dataList.length" :class="['business-wrapper', changeStatus ? 'scroll-actives':'']">
    <div class="item-wrapper mt20" v-for="item in dataList" :key="item.id">
      <div class="create-time">
        <span class="tip">{{item.type === 1 ? '考籍类': 'APP类'}}</span>
        <div class="time">创建时间：{{formatTimeStamp(new Date(item.createAt))}}</div>
      </div>
      <div class="title mt25">{{item.description}}</div>
      <div class="detail">
        <div class="detail-item mt20">
          <span class="nav-name">商机阶段</span>
          <span class="nav-detail">{{item.businessOpportunityStatusName}}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">成交几率</span>
          <span class="nav-detail">{{item.successPercent + '%'}}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">APP开通</span>
          <span class="nav-detail">{{item.app === 1 ? '已开通': '未开通'}}</span>
        </div>
      </div>
      <div class="button-group mt32">
        <div v-if="item.type === 1">
          <van-button
            v-if="item.status!== 'DEAL'"
            @click="goToConversionOrder(item)"
            class="button-item"
            round
            type="primary"
          >转为订单</van-button>
          <van-button
            v-else-if="item.status === 'DEAL' && item.app !== 1"
            class="button-item"
            round
            type="primary"
            @click="openApp(item)"
          >开通APP</van-button>
        </div>
        <!-- app类商机 如果转为了订单按钮隐藏   -->
        <div v-else-if="item.type !==1 && item.app !== 1">
          <van-button
            v-if="!item.examinationId"
            @click="goToConversionOrder(item)"
            class="button-item"
            round
            type="primary"
          >转为订单</van-button>
        </div>
      </div>
    </div>
  </div>
  <van-empty class="empty_content" v-else image="error" description="暂无数据" />
</template>
<script>
import { Button, Empty } from "vant";
import { useRouter } from "vue-router";
import { onActivated, ref } from "vue";
import { getCustomerBusinessByCondition } from "@/api/index";
import { formatTimeStamp } from "@/utils/commUtil";
export default {
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
  },
  props: {
    changeStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const dataList = ref([]);
    const getCustomerBusinessByConditions = () => {
      getCustomerBusinessByCondition(1, 9999, {
        customerId: localStorage.getItem("customerId"),
        valuesType: 1,
      }).then((res) => {
        if (res && res.body) {
          dataList.value = res.body.data;
        }
      });
    };
    const openApp = (item) => {
      console.log(item);
      let params = {
        businessId: item.id,
        idCard: item.idCard,
      };
      if (item.type === 1) {
        params.majorNo = item.appList[0].id;
        params.type = 1; // 考籍类
      }
      router.push({
        path: "/openApp",
        query: params,
      });
    };
    getCustomerBusinessByConditions();
    const goToConversionOrder = (item) => {
      router.push({
        path: "/order",
        query: {
          businessId: item.id,
          type: item.type,
          reportMessage: `${item.signTypeName}·${item.description}`,
        },
      });
    };
    onActivated(() => {
      getCustomerBusinessByConditions();
    });
    return {
      goToConversionOrder,
      dataList,
      formatTimeStamp,
      openApp,
    };
  },
};
</script>
<style lang="less" scoped>
.business-wrapper {
  padding: 0px 24px 40px 24px;
  // box-sizing: border-box;
  .item-wrapper {
    border-radius: 20px;
    padding: 32px;
    box-sizing: border-box;
    background-color: #fafafa;
    .create-time {
      display: flex;
      justify-content: space-between;
      .tip {
        display: inline-block;
        width: 92px;
        height: 36px;
        background: rgba(22, 120, 255, 0.1);
        border-radius: 18px;
        color: #1678ff;
        font-size: 20px;
        text-align: center;
        line-height: 36px;
      }
      .time {
        font-size: 24px;
        color: #999999;
      }
    }
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .detail {
      .detail-item {
        .nav-name {
          font-size: 26px;
          color: #999999;
        }
        .nav-detail {
          margin-left: 20px;
          font-size: 26px;
          color: #333333;
        }
      }
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      .button-item {
        margin-left: 20px;
        width: 160px;
        height: 58px;
        font-size: 24px;
      }
    }
  }
}
.empty_content{
  :deep(.van-empty){
    padding: 80px 0;
  }
}
</style>