<template>
  <div class="no-friend-wrapper mt20">
    <div ref="card" class="flag-wrapper">
      <div class="tips">
        <span class="icon-info">
          <van-icon name="info" />
        </span>
        <div class="icon-mess">该客户可能在大汇学邦范围内已产生消费（分析结果仅供参考）</div>
      </div>
      <div class="phone-list-wrapper">
        <div class="phone-item" v-for="(item,index) in mobileList" :key="index">{{item}}</div>
      </div>
      <!-- <div class="portrait-detail mt40">
        <div class="detail-wrapper">
          <div class="photo">
            <img v-if="qyWx && qyWx.hasOwnProperty('avatar')" class="avatar" :src="qyWx.avatar" alt />
            <img v-else class="avatar" src="/imgs/avatar.png" alt />
          </div>
          <div class="name ml24">{{info.name}}</div>
        </div>
        <div class="item-detail mt32">
          <div class="item-left">
            <span class="item-title">手机号</span>
            <span class="item-mess ml20 van-ellipsis">{{ info.mobile || "--" }}</span>
          </div>
          <div class="item-right">
            <span class="item-title">微信号</span>
            <span class="item-mess ml20 van-ellipsis">{{ info.wx || "--" }}</span>
          </div>
        </div>
        <div class="item-detail mt32">
          <div class="item-left">
            <span class="item-title">来源</span>
            <span class="item-mess ml20 van-ellipsis">{{ info.sourceName || "--" }}</span>
          </div>
          <div class="item-right">
            <span class="item-title">QQ</span>
            <span class="item-mess ml20 van-ellipsis">{{ info.qq || "--" }}</span>
          </div>
        </div>
      </div>-->
    </div>
    <div ref="clinetNav" class="clinet-nav" v-if="mobileList.length">
      <div class="nav-wrapper">
        <div class="nav-position-wrapper">
          <div class="nav-position-content-wrapper">
            <van-tabs
              v-model:active="active"
              @click="changeTab"
              color="#1678FF"
              title-inactive-color="#666"
            >
              <van-tab title="考籍" name="sExamintaion"></van-tab>
              <van-tab title="订单" name="sOrder"></van-tab>
            </van-tabs>
          </div>
        </div>
        <!-- <div v-if="active ==='sOrder'" class="button-lists">
          <tip-button :dataList="orderDataList"></tip-button>
        </div>-->
        <div class="clinet-tag-component">
          <component :is="componentName" :changeStatus="changeStatus" :mobileList="mobileList"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Tabs, Tab } from "vant";
import { ref, toRefs, reactive } from "vue";
import sOrder from "./order";
import sExamintaion from "./examination";
import tipButton from "@/components/tipButton";
// import { useStore } from "vuex";
import { getCustomerBasicInfo } from "@/api";
export default {
  props: {
    currentCustomerId: {
      type: String,
      default: "",
    },
    idCard: {
      type: String,
      default: "",
    },
    mobileList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    sOrder,
    sExamintaion,
    tipButton,
  },
  setup(props) {
    const active = ref(0);
    const card = ref(null);
    const clinetNav = ref(null);
    // const store = useStore();
    const componentName = ref("sExamintaion");
    const detaLists = reactive({
      qyWx: {},
      info: {},
    });
    const orderDataList = ref([
      {
        type: 1,
        name: "招生开通",
      },
      {
        type: 2,
        name: "教务开通",
      },
      {
        type: 3,
        name: "自行开通",
      },
      {
        type: 4,
        name: "客服订单",
      },
      {
        type: 5,
        name: "推广订单",
      },
      {
        type: 6,
        name: "机构开通",
      },
    ]);
    // const currentScrollStatus = computed(() => {
    //   // 如果是简单类型的vuex值，先把这个值转化为响应式
    //   return store.state.scrollStatus;
    // });
    if (props.currentCustomerId) {
      getCustomerBasicInfo(props.currentCustomerId).then((res) => {
        if (res && res.body) {
          detaLists.info = res.body;
          detaLists.qyWx = res.body.qyWxInfo;
          console.log(res.body);
        }
      });
    }
    // watch(currentScrollStatus, (count) => {
    //   if (count === "down") {
    //     card.value.style.opacity = 1;
    //     card.value.style.transition = "0.5s ease";
    //     clinetNav.value.classList.remove("scroll-active");
    //   } else if (count === "up") {
    //     card.value.style.opacity = 0;
    //     card.value.style.transition = "0.5s ease";
    //     clinetNav.value.classList.add("scroll-active");
    //   }
    // });
    const changeTab = (name) => {
      componentName.value = name;
    };
    return {
      active,
      changeTab,
      orderDataList,
      card,
      clinetNav,
      componentName,
      ...toRefs(detaLists),
    };
  },
};
</script>
<style lang="less" scoped>
.no-friend-wrapper {
  background: #fff;
  border-radius: 32px;
  .flag-wrapper {
    padding: 24px;
  }
  .tips {
    display: flex;
    background: rgba(22, 120, 255, 0.1);
    border-radius: 16px;
    padding: 12px 20px 12px 0px;
    box-sizing: border-box;
    .icon-info {
      text-align: center;
      display: inline-block;
      width: 60px;
      color: #1678ff;
    }
    .icon-mess {
      flex: 1;
      font-size: 24px;
      color: #1678ff;
    }
  }
  .phone-list-wrapper {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    .phone-item {
      margin-bottom: 20px;
      font-size: 34px;
      color: #333;
      font-weight: bold;
    }
  }
  .clinet-nav {
    padding: 0px 24px;
    box-sizing: border-box;
    margin-top: 42px;
    .nav-wrapper {
      background: #fff;
      .nav-position-wrapper {
        background: #fff;
        height: 100px;
        .nav-position-content-wrapper {
          width: 100%;
          height: 60px;
        }
        position: sticky;
        top: 0px;
        display: flex;
        align-items: center;
      }
    }
    .button-lists {
      margin-top: 30px;
    }
    .clinet-tag-component {
      margin-top: 30px;
    }
  }
  .portrait-detail {
    padding-bottom: 48px;
    border-bottom: 1px solid #eee;
    .detail-wrapper {
      display: flex;
      align-items: center;
      .photo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .name {
        font-weight: bold;
        font-size: 34px;
        color: #333333;
      }
    }
    .item-detail {
      display: flex;
      .item-left,
      .item-right {
        flex: 1;
      }
      .item-right {
        margin-left: 20px;
      }
      .item-title {
        display: inline-block;
        width: 100px;
        font-size: 26px;
        color: #999999;
      }
      .item-mess {
        font-size: 26px;
        color: #333;
      }
    }
  }
}
</style>