<template>
  <div class="clinet-tag">
    <div class="button-lists">
      <tip-button :dataList="buttonList" @getCurrentParams="getCurrentParams"></tip-button>
    </div>
    <div
      ref="fllowUpMessage"
      :class="['fllow-up-content mt32', changeStatus ? 'scroll-actives':'']"
    >
      <div v-if="dataList.length">
        <timer-shaft :dataList="dataList"></timer-shaft>
      </div>
      <div v-else  class="empty_content">
        <van-empty description="暂无记录" />
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Empty } from "vant";
import { ref, onActivated } from "vue";
import { getCustomerDynamicOut } from "@/api/index";
import TimerShaft from "./timerShaft";
import tipButton from "@/components/tipButton";
export default {
  props: {
    changeStatus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    TimerShaft,
    tipButton,
  },
  setup() {
    const fllowUpMessage = ref(null);
    const dataList = ref([]);
    const buttonList = ref([
      {
        name: "全部",
        type: 1,
      },
      {
        name: "跟进记录",
        type: 2,
      },
      {
        name: "活跃记录",
        type: 3,
      },
      {
        name: "其它",
        type: 4,
      },
    ]);
    const getCurrentParams = (item) => {
      let params = {};
      if (item.type === 2) {
        // 跟进记录
        params.limit = 1;
      } else if (item.type === 3) {
        // 活跃记录
        params.containQyWx = true;
      } else if (item.type === 4) {
        params.limit = 2;
      }
      getCustomerDynamicOuts(params);
    };
    const getCustomerDynamicOuts = (data) => {
      let id = localStorage.getItem("customerId");
      getCustomerDynamicOut(id, data).then((res) => {
        if (res && res.body) {
          dataList.value = res.body;
          if (dataList.value.length) {
            dataList.value = dataList.value.map((item) => {
              return {
                title: item.teacherName,
                detail: item.description,
                time: item.createAt,
                type: item.type,
              };
            });
          }
        }
      });
    };
    onActivated(() => {
      getCustomerDynamicOuts();
    });
    getCustomerDynamicOuts();
    return {
      dataList,
      fllowUpMessage,
      buttonList,
      getCurrentParams,
    };
  },
};
</script>
<style lang="less" scoped>
.clinet-tag {
  padding: 0px 0 40px 32px;
  margin-top: 30px;
  .fllow-up-content {
    padding-right: 24px;
    box-sizing: border-box;
  }
  :deep(a) {
    pointer-events: none;
  }
}
.empty_content{
  :deep(.van-empty){
    padding: 80px 0;
  }
}
</style>