<template>
  <div
    v-if="examDataList.length || appDataList.length"
    :class="['business-wrapper', changeStatus ? 'scroll-actives' : '']"
  >
    <div class="item-wrapper mt20" v-for="(item, index) in examDataList" :key="index">
      <div class="create-time">
        <span class="tip">考籍</span>
        <div class="time">
          {{ item.updateAt ? "开通时间" : "创建时间" }}：{{
            item.updateAt
              ? formatTimeStamp(new Date(item.updateAt))
              : formatTimeStamp(new Date(item.createAt))
          }}
        </div>
      </div>
      <div class="title mt25">
        {{
          `${item.signTypeName}· ${item.provinceName ? `${item.provinceName}·` : ""}${
            item.schoolName ? `${item.schoolName} ${item.majorName ? "·" : ""}` : ""
          }${item.majorName ? `${item.majorName}` : ""}·${matchLabel(degreeList, 'type', 'name', item.level) } `
        }}
      </div>
      <div class="detail">
        <div class="detail-item mt20">
          <span class="nav-name">报考类型</span>
          <span class="nav-detail">{{
            item.waitChangeType == 2
              ? item.applyTypeName
              : `${item.applyTypeName}·${item.changeBatchNo}次待转应用型`
          }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">批次</span>
          <span class="nav-detail">{{ item.batchNo || "--" }}</span>
        </div>
      </div>
    </div>
    <div class="item-wrapper mt20" v-for="(item, index) in appDataList" :key="index">
      <div class="create-time">
        <span class="tip">APP</span>
        <div class="time">
          {{ item.updateAt ? "开通时间" : "创建时间" }}：{{
            item.updateAt
              ? formatTimeStamp(new Date(item.updateAt))
              : formatTimeStamp(new Date(item.createAt))
          }}
        </div>
      </div>
      <div class="title mt25">{{ item.appTitleArr.join(",") || "--" }}</div>
      <div class="detail">
        <div class="detail-item mt20">
          <span class="nav-name">价格体系</span>
          <span class="nav-detail">{{ item.priceSystemName || "--" }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">订单金额</span>
          <span class="nav-detail">{{
            item.money ? `¥${item.money}` : item.money == 0 ? "¥ 0" : "--"
          }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">优惠金额</span>
          <span class="nav-detail">{{
            item.discountMoney
              ? `¥${item.discountMoney}`
              : item.discountMoney == 0
              ? "¥ 0"
              : "--"
          }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">已付金额</span>
          <span class="nav-detail">{{
            item.havePayMoney
              ? `¥${item.havePayMoney}`
              : item.havePayMoney == 0
              ? "¥ 0"
              : "--"
          }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">欠款</span>
          <span class="nav-detail">{{
            item.oweMoney ? `¥${item.oweMoney}` : item.oweMoney == 0 ? "¥ 0" : "--"
          }}</span>
        </div>
        <div class="detail-item mt20">
          <span class="nav-name">状态</span>
          <span :class="['nav-detail', getCurrentColor(item.payStatus)]">{{
            item.payStatus
          }}</span>
        </div>
      </div>
      <div class="button-group mt32">
        <van-button
          v-if="item.isCloseBtn"
          class="button-item"
          round
          type="default"
          @click="closeOrders(item.sn)"
          >关闭订单</van-button
        >
        <van-button
          v-if="item.isGoPayBtn"
          class="button-item"
          round
          type="primary"
          @click="goToPay(item)"
          >去支付</van-button
        >
        <van-button
          v-if="item.isGoOweBtn"
          class="button-item"
          round
          type="primary"
          @click="goOwePay(item)"
          >支付欠款</van-button
        >
      </div>
    </div>
  </div>
  <div v-else class="empty_content">
    <van-empty image="error" description="暂无开通记录" />
  </div>
</template>
<script>
import { Button, Empty, Toast } from "vant";
import {
  getCustomerBusinessAllAppOrders,
  getCustomerBusinessAllExamOrders,
} from "@/api/index";
import { closeOrder } from "@/api/common";
import { payTypeList, degreeList } from "@/utils/const";
import { formatTimeStamp, matchLabel } from "@/utils/commUtil";
import { reactive, toRefs } from "vue";

export default {
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
  },
  props: {
    changeStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      appDataList: [],
      examDataList: [],
    });
    let currentId = localStorage.getItem("customerId");
    const getDataList = () => {
      Promise.all([
        getCustomerBusinessAllAppOrders(currentId),
        getCustomerBusinessAllExamOrders(currentId),
      ]).then((res) => {
        if (res[0].body && res[0].body.length) {
          state.appDataList = res[0].body;
          res[0].body.forEach((ele) => {
            ele.appTitleArr = ele.goods.map((item) => {
              return item.name;
            });
            // 去支付按钮：待支付、待财务审核、非优惠订单财务审核被驳回
            if (
              ele.payStatus === "待支付" ||
              ele.payStatus === "待财务审核" ||
              ele.payStatus === "财务审核被驳回"
            ) {
              ele.isGoPayBtn = true;
            }
            //关闭订单按钮:待支付、待财务审核、非优惠订单财务审核被驳回、优惠订单优惠待审批
            if (
              ele.payStatus === "待支付" ||
              ele.payStatus === "待财务审核" ||
              ele.payStatus === "财务审核被驳回" ||
              ele.payStatus === "优惠待审批"
            ) {
              if (ele.payStatus === "财务审核被驳回" || ele.payStatus === "优惠待审批") {
                if (ele.payStatus === "财务审核被驳回") {
                  ele.isCloseBtn = true;
                } else {
                  if (ele.orderDiscount === 1) {
                    ele.isCloseBtn = true;
                  } else {
                    ele.isCloseBtn = false;
                  }
                }
              } else {
                ele.isCloseBtn = true;
              }
            }
            //支付欠款按钮：非优惠订单已开通且欠款金额大于0
            if (
              ele.payStatus === "已开通" &&
              ele.oweMoney > 0 &&
              ele.orderDiscount !== 1
            ) {
              ele.isGoOweBtn = true;
            } else {
              ele.isGoOweBtn = false;
            }
          });
        }
        if (res[1].body && res[1].body.length) {
          state.examDataList = res[1].body;
        }
      });
    };
    getDataList();
    const goToPay = (item) => {
      context.emit("goToOrder", item);
    };
    const goOwePay = (item) => {
      context.emit("goToOwnPay", item);
    };
    const getCurrentColor = (status) => {
      if (status == "已开通") {
        return "color_success";
      } else if (status == "订单关闭" || status == "未知") {
        return "coloe_cancel";
      } else if (status == "待财务审核" || status == "优惠待审批" || status == "待支付") {
        return "color_warning";
      } else {
        return "color_error";
      }
    };
    const closeOrders = (sn) => {
      closeOrder([sn]).then((res) => {
        if (res && res.body) {
          Toast({
            message: "取消成功",
            onClose: () => {
              getDataList();
            },
          });
        }
      });
    };
    return {
      ...toRefs(state),
      formatTimeStamp,
      payTypeList,
      goToPay,
      degreeList,
      goOwePay,
      matchLabel,
      getCurrentColor,
      closeOrders,
    };
  },
};
</script>
<style lang="less" scoped>
.business-wrapper {
  padding: 0px 24px 40px 24px;
  .item-wrapper {
    border-radius: 20px;
    padding: 32px;
    box-sizing: border-box;
    background-color: #fafafa;
    .create-time {
      display: flex;
      justify-content: space-between;
      .tip {
        display: inline-block;
        width: 92px;
        height: 36px;
        background: rgba(22, 120, 255, 0.1);
        border-radius: 18px;
        color: #1678ff;
        font-size: 20px;
        text-align: center;
        line-height: 36px;
      }
      .time {
        font-size: 24px;
        color: #999999;
      }
    }
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .detail {
      .detail-item {
        .nav-name {
          font-size: 26px;
          color: #999999;
        }
        .nav-detail {
          margin-left: 20px;
          font-size: 26px;
        }
      }
    }
    .button-group {
      display: flex;
      justify-content: flex-end;
      .button-item {
        width: 160px;
        height: 58px;
        margin-right: 20px;
        font-size: 24px;
      }
    }
  }
}
.empty_content {
  :deep(.van-empty) {
    padding: 80px 0;
  }
}
</style>
