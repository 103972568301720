
<template>
  <div id="group_wrapper" class="btton-wrapper" v-slide:sync>
    <div class="button-content-wrapper">
      <div
        v-for="(item, index) in dataList"
        @click="handleClick(item)"
        :key="index"
        :class="['button-list',`${currentTag == item.type ? 'active' : ''}`]"
      >
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { slide } from "@/directive/slide";
export default {
  props: {
    dataList: {
      type: Array,
      defalut: () => [],
    },
  },
  directives: { slide },
  setup(props, context) {
    const currentTag = ref(1); // 按钮分类
    const handleClick = (item) => {
      currentTag.value = item.type;
      context.emit("getCurrentParams", item);
    };
    return {
      currentTag,
      handleClick,
    };
  },
};
</script>
<style lang="less" scoped>
.btton-wrapper {
  overflow: hidden;
  .button-content-wrapper {
    box-sizing: border-box;
    &::-webkit-scrollbar {
      display: none;
    }
    white-space: nowrap;
    .button-list {
      display: inline-block;
      margin-right: 12px;
      border: 1px solid #dddddd;
      border-radius: 30px;
      padding: 8px 24px;
      background: #fff;
      font-size: 24px;
      color: #999999;
      box-sizing: border-box;
    }
    .active {
      border: 1px solid #1678ff;
      background: rgba(22, 120, 255, 0.1);
      color: #1678ff;
    }
  }
}
</style>