<template>
  <div class="examination-wrapper">
    <div v-if="currentExaminationList.length">
      <div class="content-wrapper" v-for="item in currentExaminationList" :key="item.id">
        <div class="title">{{ formatExamination(item.names) }}</div>
        <div class="list mt25">
          <span class="nav">报考类型</span>
          <span class="detail ml20">{{
            item.waitChangeType == 2
              ? item.applyTypeName
              : `${item.applyTypeName}·${item.changeBatchNo}次待转应用型`
          }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">批次</span>
          <span class="detail ml20">{{ item.batchNo }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">状态</span>
          <span class="detail ml20">{{ matchLabel(changeStatusList3, 'value', 'label', item.status ) }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">APP开通</span>
          <span class="detail ml20">{{ item.appPass ? "已开通" : "未开通" }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">当前步骤</span>
          <span class="detail ml20">{{ `${item.currentStep || '--'}` }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">开通人</span>
          <span class="detail ml20">{{ item.passPerson }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">负责人</span>
          <span class="detail ml20">{{ item.responsiblePerson }}</span>
        </div>
      </div>
    </div>
    <van-empty v-else class="mt25" image="error" description="暂无数据" />
  </div>
</template>

<script>
// import { getExaminationList } from "@/api/index";
// import { getStudentExaminationInfo } from "@/api/studentDetails";
import { noFindExamination } from "@/api/common";
import { ref } from "vue";
import { Empty } from "vant";
import { goodsBigTypeList, changeStatusList3 } from "@/utils/const";
import { matchLabel } from "@/utils/commUtil";
export default {
  components: {
    [Empty.name]: Empty,
  },
  props: {
    mobileList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const currentExaminationList = ref([]);
    const mobilesList = ref(props.mobileList);
    let stringParams = ref([]);
    mobilesList.value.forEach((item) => {
      stringParams.value.push(`mobiles=${item}`);
    });
    const formatExamination = (goods) => {
      const signType = matchLabel(goodsBigTypeList, "type", "name", goods.signType);
      let str = [goods.provinceName, goods.schoolName, goods.examinationName]
        .filter(Boolean)
        .join("·");
      str = `${signType}·${str}`;
      if (goods.signType === 1) {
        // 单门课程
        str += "·单门课程";
      } else {
        // 整专业
        str += "·整专业";
      }
      return str;
    };

    const getExaminationLists = () => {
      let data = stringParams.value.join("&");
      noFindExamination(data).then((res) => {
        if (res && res.body) {
          currentExaminationList.value = res.body;
        }
        console.log(res);
      });
    };
    if (mobilesList.value.length) {
      getExaminationLists();
    }
    return {
      currentExaminationList,
      changeStatusList3,
      formatExamination,
      matchLabel
    };
  },
};
</script>
<style lang="less" scoped>
.examination-wrapper {
  padding-bottom: 30px;
  .content-wrapper {
    overflow: hidden;
    padding-bottom: 32px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .title {
    margin-top: 32px;
    font-size: 32px;
    font-weight: bold;
    color: #333;
  }
  .list {
    font-size: 26px;
    .nav {
      display: inline-block;
      width: 114px;
      color: #999;
    }
    .detail {
      color: #333;
    }
  }
}
</style>
