<template>
  <div v-if="!isShowOrder" class="clinet-wrapper">
    <!-- <s-header></s-header> -->
    <div class="content-wrapper">
      <div class="scroll-wrapper">
        <div v-if="isNofirend && noFirendStauts" class="card-wrapper">
          <p class="no-firend-tip">该好友不在我的客户列表内</p>
          <p class="add-button">
            <van-button
              @click="addFirend"
              class="add-buttons"
              icon="plus"
              round
              size="large"
              type="primary"
              >去添加</van-button
            >
          </p>
        </div>
        <div class="no-friend-wrapper">
          <s-no-friend
            v-if="isNofirend && mobileList.length"
            :mobileList="mobileList"
          ></s-no-friend>
        </div>
        <div v-if="!isNofirend" ref="card" class="card-wrapper">
          <div class="portrait-wrapper">
            <div class="portrait-left">
              <div class="portrait">
                <img v-if="qyWx && qyWx.avatar" class="avatar" :src="qyWx.avatar" alt />
                <img v-else class="avatar" src="/imgs/avatar.png" alt />
              </div>
              <div class="personal-base">
                <div class="name">{{ info.name || "--" }}</div>
                <div class="star" v-if="info.star">
                  <van-rate v-model="info.star" readonly />
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="portrait-right">
              <div class="portrait-tag">{{ info.statusName }}</div>
            </div>
          </div>
          <div class="base-detail">
            <div class="detail-left">
              <div class="detail-list">
                <span>手机号</span>
                <div class="van-ellipsis">{{ info.mobile || "--" }}</div>
              </div>
              <div class="detail-list">
                <span>来源</span>
                <div class="van-ellipsis">{{ info.sourceName || "--" }}</div>
              </div>
            </div>
            <div class="detail-right">
              <div class="detail-list">
                <span>微信号</span>
                <div class="van-ellipsis">{{ info.wx || "--" }}</div>
              </div>
              <div class="detail-list">
                <span>QQ</span>
                <div class="van-ellipsis">{{ info.qq || "--" }}</div>
              </div>
            </div>
          </div>
          <div class="tag-lists" v-if="labelList.length">
            <span
              v-for="item in labelList"
              :key="item.id"
              :class="[`background-${item.color} color-${item.color}`, 'mb8']"
              >{{ item.content }}</span
            >
          </div>
        </div>
        <div v-if="!isNofirend" ref="clinetNav" class="clinet-nav">
          <div class="nav-wrapper">
            <div class="nav-position-wrapper">
              <div class="nav-shape">
                <div>
                  <van-tabs
                    v-model:active="active"
                    @click="changeTab"
                    color="#1678FF"
                    title-inactive-color="#666"
                  >
                    <van-tab title="动态" name="sDynamic"></van-tab>
                    <van-tab title="商机" name="sBusiness"></van-tab>
                    <van-tab title="开通记录" name="sRecord"></van-tab>
                    <van-tab title="详情" name="sPersonalDetail"></van-tab>
                  </van-tabs>
                </div>
              </div>
            </div>

            <div class="clinet-tag-component">
              <component
                :is="componentName"
                :changeStatus="changeStatus"
                @goToOrder="goToOrder"
                @goToOwnPay="goOwnPay"
              ></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isNofirend" class="fixed-button-group">
      <s-supernatant :dataList="buttonLists.message"></s-supernatant>
    </div>
    <div v-if="isShow">
      <s-choose-Roles
        v-if="isShow"
        @closeModel="closeModel"
        @success="addSuccess"
      ></s-choose-Roles>
    </div>
  </div>
  <my-pay-order v-else :dataList="getPayList" @closePay="closePay"></my-pay-order>
</template>
<script>
// import sHeader from "@/components/header";
import sDynamic from "./components/dynamic";
import sBusiness from "./components/business";
import sRecord from "./components/record";
import sNoFriend from "./components/noFirend";
import sSupernatant from "@/components/supernatant";
import myPayOrder from "@/components/payorder";
import sPersonalDetail from "./components/personalDetail";
import sChooseRoles from "@/components/chooseRoles";
import { Tab, Tabs, Icon, Sticky, Button, ActionSheet, Rate } from "vant";
import { reactive, ref, toRefs, onDeactivated, onActivated } from "vue";
import { getCustomerBasicInfo, getCustomerId } from "@/api";
import { checkSnPays } from "@/utils/check";
import { buttonList } from "@/utils/const";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "Client",
  components: {
    // 使用组件必须为驼峰
    // sHeader,
    sDynamic,
    sBusiness,
    sRecord,
    sNoFriend,
    sSupernatant,
    sChooseRoles,
    sPersonalDetail,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [Rate.name]: Rate,
    [Sticky.name]: Sticky,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet,
    myPayOrder,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isNofirend = ref(true);
    const isShow = ref(false);
    const componentName = ref("sDynamic");
    const changeStatus = ref(false); // 改变内容高度
    const card = ref(null);
    const currentCustomerId = ref(""); // 用户Id值
    const currentIdCard = ref("");
    const mobileList = ref([]); // 获取相关联手机号
    const noFirendStauts = ref(true); // 显示隐藏添加好友卡片
    const clinetNav = ref(null);
    const currentStatus = ref(false);
    const isShowOrder = ref(false);
    const getPayList = ref({}); // 支付数据
    let clinetInfo = reactive({
      info: {},
      qyWx: {},
      labelList: [],
    }); // 客户基本信息
    let buttonLists = reactive({
      message: buttonList,
    });
    // const currentScrollStatus = computed(() => {
    //   // 如果是简单类型的vuex值，先把这个值转化为响应式
    //   return store.state.client.scrollStatus;
    // });
    const getCustomerBasicInfos = (id) => {
      getCustomerBasicInfo(id, { containQyWxInfo: true }).then((res) => {
        if (res && res.body) {
          clinetInfo.info = res.body;
          clinetInfo.qyWx = res.body.qyWxInfo;
          clinetInfo.labelList = res.body.labelList;
        }
      });
    };
    const changeTab = (name) => {
      componentName.value = name;
      if (componentName.value === "sDynamic") {
        buttonLists.message = reactive(buttonList);
      } else if (componentName.value === "sPersonalDetail") {
        buttonLists.message = reactive([
          {
            icon: "hxb-icon_bianji",
            title: "编辑客户",
            url: "/edit",
          },
        ]);
      } else {
        buttonLists.message = reactive([]);
      }
    };
    const closeModel = () => {
      isShow.value = false;
    };
    // 获取客户Id
    const getCustomerIds = (cb) => {
      let customerQyWxUserId = localStorage.getItem("customerQyWxUserId");
      if (customerQyWxUserId) {
        getCustomerId({
          customerQyWxUserId: customerQyWxUserId,
        }).then((res) => {
          if (res && res.body) {
            if (res.body.hasAdd) {
              isNofirend.value = false;
              currentCustomerId.value = res.body.id;
              localStorage.setItem("customerId", res.body.id);
              if (res.body.id) {
                getCustomerBasicInfos(res.body.id);
              }
            } else {
              isNofirend.value = true;
              currentCustomerId.value = res.body.id;
              currentIdCard.value = res.body.idCard;
              mobileList.value = res.body.mobiles;
            }
            cb();
          }
        });
      }
    };
    const addSuccess = () => {
      getCustomerIds(() => {
        isNofirend.value = false;
      });
    };
    getCustomerIds(() => {});
    const addFirend = () => {
      isShow.value = true;
    };
    const goOwnPay = (item) => {
      router.push({
        path: "/arrearage",
        query: {
          sn: item.sn,
          money: item.money,
          havePayMoney: item.havePayMoney,
        },
      });
    };
    const goToOrder = (item) => {
      console.log(item);
      checkSnPays(
        () => {
          getPayList.value.currentType = item.type;
          getPayList.value.totalPrice = item.money;
          getPayList.value.sn = item.sn;
          getPayList.value.title = item.description;
          getPayList.value.reportMessage = "";
          getPayList.value.isDisabled = item.payStatus === "待财务审核" ? true : false;
          isShowOrder.value = true;
          getPayList.value.checkSuccessList = item.goods;
          getPayList.value.orderDiscount  = item.orderDiscount // 是不是优惠订单 1 是 2 不是
        },
        item.sn,
        () => {
          console.log("提示消失回调！");
        }
      );
    };
    const closePay = () => {
      isShowOrder.value = false;
    };
    // 离开就改变状态值
    onDeactivated(() => {
      isShowOrder.value = false;
    });
    onActivated(() => {
      // const customerId = localStorage.getItem("customerId");
      // if (customerId) {
      //   getCustomerBasicInfos(customerId);
      // }
      getCustomerIds(() => {});
    });
    return {
      componentName,
      card,
      clinetNav,
      changeStatus,
      currentStatus,
      isNofirend,
      currentCustomerId,
      isShow,
      isShowOrder,
      getPayList,
      currentIdCard,
      mobileList,
      store,
      noFirendStauts,
      buttonLists,
      closePay,
      goToOrder,
      closeModel,
      goOwnPay,
      changeTab,
      addFirend,
      addSuccess,
      ...toRefs(clinetInfo),
    };
  },
};
</script>
<style lang="less" scoped>
.clinet-wrapper {
  height: 100%;
  .content-wrapper {
    height: 100%;
    background: #f5f5f5;
  }
  .fixed-button-group {
    position: fixed;
    right: 32px;
    bottom: 80px;
    z-index: 999;
  }
  .scroll-wrapper {
    position: fixed;
    width: 702px;
    top: 24px;
    left: 24px;
    height: 100%;
    overflow: auto;
    margin-bottom: 10px;
    z-index: 2;
    // .no-friend-wrapper {
    //   padding-bottom: 45px;
    // }
  }
  .card-wrapper {
    .no-firend-tip {
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      margin: 80px 0px 32px 0px;
    }
    .add-button {
      text-align: center;
      .add-buttons {
        width: 260px;
        height: 80px;
        font-size: 32px;
      }
    }
    .portrait-wrapper {
      display: flex;
      justify-content: space-between;
      .portrait-left {
        display: flex;
        .portrait {
          height: 80px;
          width: 80px;
          // border: 1px solid green;
          overflow: hidden;
          border-radius: 50%;
          .avatar {
            width: 80px;
            height: 80px;
          }
        }
        .personal-base {
          margin-left: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name {
            font-size: 34px;
            color: #333333;
            font-weight: bold;
          }
          .star {
            :deep(.van-rate__icon--full) {
              color: #ff6543;
            }
          }
        }
      }
      .portrait-right {
        display: flex;
        align-items: center;
        .portrait-tag {
          padding: 10px 20px;
          background-color: #1678ff;
          color: #fff;
          font-size: 20px;
          border-radius: 30px;
        }
      }
    }
    .base-detail {
      display: flex;
      font-size: 26px;
      margin-top: 12px;
      .detail-right {
        margin-left: 38px;
      }
      .detail-list {
        display: flex;
        align-items: center;
        margin-top: 20px;
        span {
          color: #999;
          display: inline-block;
          width: 80px;
        }
        div {
          margin-left: 10px;
          width: 200px;
        }
      }
      span {
        display: inline-block;
        width: 85px;
      }
      b {
        margin-left: 20px;
      }
    }
    .tag-lists {
      margin-top: 24px;
      span {
        margin-right: 8px;
        display: inline-block;
        padding: 4px 16px;
        font-size: 20px;
        border-radius: 18px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .clinet-nav {
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 32px;
    .nav-wrapper {
      background: #fff;
      border-radius: 32px;
      .nav-position-wrapper {
        .nav-shape {
          display: flex;
          align-items: center;
          background: #fff;
          border-top-right-radius: 32px;
          border-top-left-radius: 32px;
          height: 100px;
          div {
            width: 100%;
          }
        }
        position: sticky;
        overflow: hidden;
        background: #f5f5f5;
        top: 0px;
        z-index: 99;
      }
      // /deep/.van-tab__text--ellipsis {
      //   overflow: inherit;
      // }
    }
  }
  .content {
    padding: 0px 32px 66px 32px;
    text-align: center;
    p {
      border-bottom: 1px solid #eeeeee;
      padding: 36px 0px;
      font-size: 30px;
      color: #333;
    }
    & > p:last-child {
      border-bottom: none;
    }
  }
}
</style>
