
<template>
  <div class="supernatant-wrapper">
    <div
      class="item"
      v-for="(item, index) in lists.dataLists"
      @click="handleClick(item.url)"
      :key="index"
    >
      <div class="icon-wrapper">
        <i :class="['iconfont', item.icon]"></i>
      </div>
      <div class="title">
        <span>{{item.title}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "vant";
import { reactive, watchEffect } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Icon.name]: Icon,
  },
  setup(props) {
    let lists = reactive({
      dataLists: [],
    });
    watchEffect(() => {
      lists.dataLists = props.dataList;
    });
    const router = useRouter();
    const handleClick = (url) => {
      router.push({
        path: url,
      });
    };
    return {
      lists,
      handleClick,
    };
  },
};
</script>
<style lang="less" scoped>
.supernatant-wrapper {
  display: flex;
  flex-direction: column;
  .item {
    height: 120px;
    width: 120px;
    border-radius: 20px;
    box-shadow: 0px 4px 12px 0px rgba(22, 120, 255, 0.2);
    background: #1678ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
    & > .item:last-child {
      margin-top: 0px;
    }
    .icon-wrapper {
      i{
        font-size: 40px;
      }
    }
    .title {
      font-size: 16px;
    }
  }
}
</style>