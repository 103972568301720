<template>
  <div class="order-wrapper">
    <tip-button :dataList=" buttonList" @getCurrentParams="getCurrentParams"></tip-button>
    <div v-if="orderList.length">
      <div class="content-wrapper" v-for="(item,index) in orderList" :key="index">
        <div class="title">{{item.description}}</div>
        <div class="list mt25">
          <span class="nav">消费类型</span>
          <span class="detail ml20">{{formatConsumetype(item.consumeType) }}</span>
        </div>
        <div class="list mt25" v-if="curentType !== 3">
          <span class="nav">价格体系</span>
          <span class="detail ml20">{{item.priceStructure||'--'}}</span>
        </div>
        <div class="list mt25">
          <span class="nav">金额</span>
          <span class="detail ml20">¥{{formatOrderMoney(item.money)}}</span>
        </div>
        <div class="list mt25">
          <span class="nav">支付方式</span>
          <span class="detail ml20">{{statusList[item.payPlatform -1]}}</span>
        </div>
        <div class="list mt25" v-if="curentType !== 3">
          <span class="nav">开通人</span>
          <span class="detail ml20">{{item.passPerson}}</span>
        </div>
        <div class="list mt25">
          <span class="nav">创建时间</span>
          <span class="detail ml20">{{formatTime(item.createdAt) }}</span>
        </div>
        <div class="list mt25">
          <span class="nav">支付时间</span>
          <span class="detail ml20">{{formatTime(item.payAt) || '--'}}</span>
        </div>
      </div>
    </div>
    <van-empty v-else class="mt25" image="error" description="暂无数据" />
  </div>
</template>
<script>
import { matchLabel, formatMoney, formatTimeMs } from "@/utils/commUtil";
// import { getAppOrderList } from "@/api/studentDetails";
import { formatTimeStamp } from "@/utils/commUtil";
import { consumeTypeList, consumeTypeList2, buyTypeList } from "@/utils/const";
import tipButton from "@/components/tipButton";
import { Empty } from "vant";
import { ref } from "vue";
import { noFindOrderList } from "@/api/common";
export default {
  components: {
    tipButton,
    [Empty.name]: Empty,
  },
  props: {
    mobileList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const statusList = [
      "支付宝支付",
      "微信支付",
      "免费支付",
      "线下支付",
      "IOS内购",
    ];
    const buttonList = [
      {
        type: 1,
        name: "直招开通",
      },
      {
        type: 2,
        name: "教务开通",
      },
      {
        type: 3,
        name: "自行购买",
      },
      {
        type: 4,
        name: "客服订单",
      },
      {
        type: 5,
        name: "推广订单",
      },
      {
        type: 6,
        name: "机构开通",
      },
    ];
    const curentType = ref(1);
    const orderList = ref([]);
    const moilesList = ref(props.mobileList);
    console.log(moilesList);
    const formatConsumetype = (consumetype) => {
      return (
        matchLabel(
          curentType.value == 1 || curentType.value == 2
            ? consumeTypeList
            : consumeTypeList2,
          "value",
          "label",
          consumetype
        ) || "--"
      );
    };
    const formatOrderMoney = (money) => {
      return money ? `${formatMoney(money)}` : "--";
    };
    const formatBuyType = (buyType) => {
      return matchLabel(buyTypeList, "value", "label", buyType) || "--";
    };
    const formatTime = (time) => {
      return formatTimeMs(time) || "--";
    };
    let stringParams = ref([]);
    moilesList.value.forEach((item) => {
      stringParams.value.push(`mobiles=${item}`);
    });
    const getOrderLists = (type) => {
      let data = stringParams.value.join("&");
      noFindOrderList(data, type).then((res) => {
        if (res && res.body) {
          orderList.value = res.body;
        }
        console.log(res);
      });
    };
    if (moilesList.value.length) {
      getOrderLists({
        type: curentType.value,
      });
    }
    const getCurrentParams = (item) => {
      curentType.value = item.type;
      getOrderLists({
        type: item.type,
      });
    };
    return {
      orderList,
      formatTimeStamp,
      statusList,
      buttonList,
      formatConsumetype,
      formatOrderMoney,
      formatBuyType,
      formatTime,
      curentType,
      getCurrentParams,
    };
  },
};
</script>
<style lang="less" scoped>
.order-wrapper {
  padding-bottom: 30px;
  .content-wrapper {
    margin-top: 30px;
    overflow: hidden;
    padding-bottom: 32px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .title {
    margin-top: 32px;
    font-size: 32px;
    font-weight: bold;
    color: #333;
  }
  .list {
    font-size: 26px;
    .nav {
      display: inline-block;
      width: 114px;
      color: #999;
    }
    .detail {
      color: #333;
    }
  }
}
</style>