<template>
  <div class="timer-wrapper">
    <div class="item" v-for="(item,index)  in dataList" :key="index">
      <div class="left">
        <div class="circle"></div>
        <div class="line-wrapper">
          <div class="line"></div>
        </div>
      </div>
      <div class="right">
        <div class="wrapper">
          <!-- <div class="title">图文浏览</div> -->
          <div class="title">{{arrList[item.type-1]||'图文浏览'}}</div>
          <div class="time">{{formatTimeStamp(new Date(item.time)) }}</div>
        </div>
        <div class="detail" v-html="item.detail"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatTimeStamp } from "@/utils/commUtil";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const currentStatus = computed(() => {
      return store.getters.client.getPorStatus();
    });
    const arrList = ["跟进", "操作", "图片查看", "", "文件浏览", "试学"];
    onMounted(() => {
      window.addEventListener(
        "scroll",
        () => {
          // console.log(currentStatus);
        },
        true
      );
    });
    return {
      currentStatus,
      arrList,
      formatTimeStamp,
    };
  },
};
</script>
<style lang="less" scoped>
.timer-wrapper {
  .item {
    display: flex;
    .left {
      .circle {
        width: 24px;
        height: 24px;
        border: 5px solid #1678ff;
        border-radius: 50%;
      }
      .line-wrapper {
        height: calc(100% - 36px);
        display: flex;
        justify-content: center;
        .line {
          height: 100%;
          background: #eeeeee;
          border: 2px solid #eeeeee;
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 24px;
      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 30px;
          font-weight: bold;
          color: #333;
        }
        .time {
          font-size: 22px;
          color: #999;
        }
      }
      .detail {
        margin-top: 12px;
        font-size: 26px;
        color: #999;
        padding-bottom: 48px;
        box-sizing: border-box;
        :deep(img) {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
</style>